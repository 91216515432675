import { useState, useEffect } from 'react';
import '../../ConsignmentNote.css';
import axios from "axios";
import Global from "../../APIConfig";
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Barcode from "../../Barcode";

function Manidest_print(){
    let navigate = useNavigate();
  const {slipno} = useParams();
  const {headers1,headers2,logout}=AuthUser();
  const [shipmentlist,setShipmentlist] = useState([]);
  const [manifestdata,setManifestdata] = useState([]);
    useEffect(() => {
      GetShipmentdata();
    }, []);
    const GetShipmentdata=()=>{
      axios({
          method: 'post',
          url: Global.baseUrl+'PrintManifest',
          headers:headers1,
          data: {
            uniqueid: slipno
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var shipmentdata=response.data.value;
              var manifestdata=response.data.manifest
              setShipmentlist(shipmentdata);
              setManifestdata(manifestdata);
              
          }
        })
        .catch(function (error) {
          if(error.response.status==401){
              toast.error(error.response.statusText);
              logout();
              navigate('/');
              window.location.reload();
          }
          // console.log(error);
      });
    }
        const handlePrint = () => {
          window.print(); // Trigger the browser's print functionality
        };
return(
        <div class="container mt-3">
            {/* <button onClick={handlePrint}>Print Page</button> */}
        <div class="row justify-content-between">
            <div class="col col-md-4">
                {/* <h2><img src={`${Global.logoUrl}${printlogo}`} className="img-fluid" /></h2> */}
            </div>
            <div class="col col-md text-center">
                <h2 class="m-0 p-0">Manifest</h2>
                <div class="d-flex justify-content-center">
                    <div class="p-1">Generated on {manifestdata.mdate}</div>
                </div>
            </div>
            <div class="col-3 col-md-3 text-end">
                {/* <img src="https://i.imgur.com/Sx6F4aC.png" class="img-fluid" style={{"max-height":"40px"}} /> */}
                {/* <h5>MID: {slipno}</h5> */}
            </div>
        </div>
        <div class="row justify-content-between">
            <div class="col col-md-4">
                <span>Mfno: {slipno}</span><br/>
                <span>Forwarder: - </span><br/>
                <span>Vehicle: - </span><br/>
                <span>FWD No: - </span><br/>
            </div>
            <div class="col col-md text-center">
                
            </div>
            <div class="col-3 col-md-3 text-end">
                <span>Manifest Date: {manifestdata.mdate}</span><br/>
                <span>To Branch: - </span><br/>
                <span>From Branch: - </span><br/>
                
            </div>
        </div>
        <table className="table table-bordered border-dark border-2" style={styles.tablecollsap}>
            <thead>
              <tr>
                <th style={styles.cell}>Sr No</th>
                <th style={styles.cell}>AWB #</th>
                <th style={styles.cell}>Consignor</th>
                <th style={styles.cell}>Origin</th>
                <th style={styles.cell}>Consignee</th>
                <th style={styles.cell}>Destination</th>
                <th style={styles.cell1}>Pincode</th>
                <th style={styles.cell1}>Mode</th>
                <th style={styles.cell1}>PCS</th>
                <th style={styles.cell1}>Weight</th>
                <th style={styles.cell1}>COD</th>
                <th style={styles.cell1}>Topay</th>
              </tr>
            </thead>
            <tbody>
            {shipmentlist != undefined ? 
                <>
                {shipmentlist.map((mani,index)=>{
                return(
                    <tr>
                        <td style={styles.cell}>{index+1}</td>
                        <td style={styles.cell}>
                            {mani.slip_no}
                        </td>
                        <td style={styles.cell}>
                            {mani.sender_name}
                        </td>
                        <td style={styles.cell}>{mani.sendercity}</td>
                        <td style={styles.cell}>
                            {mani.reciever_name}
                        </td>
                        <td style={styles.cell}>
                        {mani.receivercity}
                        </td>
                        <td style={styles.cell1}>
                            0
                        </td>
                        <td style={styles.cell}>
                        {mani.booking_mode}
                        </td>
                        <td style={styles.cell1}>
                            {mani.pieces}
                        </td>
                        <td style={styles.cell1}>
                            {mani.volumetric_weight}
                        </td>
                        <td style={styles.cell1}>
                            {mani.total_cod_amt}
                        </td>
                        <td style={styles.cell1}>
                            0
                        </td>
                    </tr>
                    );
                })}   
            </>
            :
            <tr>
               <td colSpan="10" className="text-center">Record Not Found</td>
            </tr>
           } 
            </tbody>
        </table>

        <hr />
        <div class="row mt-0">
            <div class="col-6 col-md-3">
                Receiver Name:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Date/Time:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Receiver Signature:
                <h4 class="py-5">____________</h4>
            </div>
            <div class="col-6 col-md-3">
                Total Shipments: {manifestdata.manicount}
            </div>
        </div>


    </div>
);
}


// CSS styles as JavaScript objects
const styles = {
    tablecollsap:{
        width:'100%',
        tableLayout: 'fixed',
    },
    cell: {
      width: '100px', // Fixed cell width
      height: '50px', // Fixed cell height
      border: '1px solid #000', // Border for cells
      overflow: 'hidden', // Hides overflow content
      whiteSpace: 'nowrap', // Prevents text wrapping
      textOverflow: 'ellipsis', // Adds ellipsis for overflow text
      padding: '5px', // Padding inside cells
    },
    cell1: {
        width: '60px', // Fixed cell width
        height: '50px', // Fixed cell height
        border: '1px solid #000', // Border for cells
        overflow: 'hidden', // Hides overflow content
        whiteSpace: 'nowrap', // Prevents text wrapping
        textOverflow: 'ellipsis', // Adds ellipsis for overflow text
        padding: '5px', // Padding inside cells
      },
  };

export default Manidest_print;