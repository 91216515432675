import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ImportPincode() {
    const navigate = useNavigate();
    const { headers1, headers2, logout } = AuthUser();
    const [Loading, setLoading] = useState(false);
    const [Subloading, setSubloading] = useState(true);
    const [file, setFile] = useState(null);
    const [servicelist, setServicelist] = useState([]);
    const [serviceid, setServiceid] = useState('');

    useEffect(() => {
        GetServicelist();
    }, []);

    const GetServicelist=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Servicelistforpincode',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setServicelist(data);
            }
            else
            {
                setServicelist();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/assets/import_pincode_example.xlsxt`; // Path to your file
        link.download = 'import_pincode_example.xlsx'; // Name of the file to be downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const handleUpload = () => {
        if(serviceid =="")
        {
            toast.warn('Please Select Service type');
            return
        }
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('serviceid', serviceid);
        axios.post(Global.baseUrl + 'uploadpincode', formData, {
            headers: headers2,
          })
          .then((response) => {
            console.log(response.data);
            toast.success('File uploaded successfully');
            setLoading(false);
          })
          .catch((error) => {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
          });
      };

      const handleCountryChange = (event) => {
        setServiceid(event.target.value);
    };

    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading == true ? <MainLoader /> :
                    <>
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div class="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                                    <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li class="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">Import Pincode</li>
                                    </ol>
                                </nav>
                                <div class="d-flex">
                                    <div class="">
                                        <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                            <i class="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div class="ps-2 ">
                                        <h2 class="h4">Import Pincode</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md">
                                <div class="custom_nav_data_table">

                                </div>
                            </div>


                        </div>

                        <section class="data_table">
                            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                                <table class="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="bold">NOTE: </span>
                                                <span class="fw-normal text-capitalize">Only xls or xlsx file must be uploaded. Use sequence of column table in excel as example.xlsx</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <span className="bold">Format for excel sheet: </span>
                                                <span class="fw-normal text-capitalize">Postal code | Country  | State | City</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <span className="bold">Postal code: </span><span class="fw-normal text-capitalize">Pincode must be Numeric value ie. 1234567890.Don't use special character or coma.</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <span className="bold">Country: </span><span class="fw-normal text-capitalize">Do not use special character or coma or bracket. ie. Warangal (Kazipet-Hanmakonda) this in not valid and could not upload.</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <span className="bold"> State: </span><span class="fw-normal text-capitalize">Do not use special character or coma or bracket. ie. Warangal (Kazipet-Hanmakonda) this in not valid and could not upload.</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="bold">City: </span><span class="fw-normal text-capitalize">Do not use special character or coma or bracket. ie. Warangal (Kazipet-Hanmakonda) this in not valid and could not upload.</span></td>
                                        </tr>
                                        <tr>

                                        </tr>
                                        <tr>

                                        </tr>
                                        <tr>
                                            <td align="center"><a onClick={handleDownload}><img src="https://en.grk-co.com/assets/images/images/excel_download.png" title="Download Excel File" /></a> (folder images) for download example file.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="">
                                                <label htmlFor="">Select Service</label>
                                                <select className="form-select" onChange={handleCountryChange}>
                                                <option value="">-- Please Select --</option>
                                                {servicelist.map((list) => {
                                                    return (
                                                        <option value={list.unique_id}>{list.services_name}, {list.product_type}, {list.booking_type}</option>
                                                    );
                                                })}
                                            </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>  <input type="file" onChange={handleFileChange} accept=".csv,.xlsx,.xls" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <button className="btn btn-primary" onClick={handleUpload}>Upload</button>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
            </>
            }
            <Footer />
        </main >
        </>
        );
}
export default ImportPincode;