import React, { useState, useEffect }  from 'react';
// import Header from "../include/header";
// import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import AuthUser from "../../AuthUser";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';



function Invoicedetail(){
  let navigate = useNavigate();
  const{headers1,headers2,logout}=AuthUser();
  const {invoiceno} = useParams();
  const [list, setList] = useState([]);
  const [shipmentdata, setShipmentdata] = useState('');
  const [Loading, setLoading] = useState(true);
  const [Subloading, setSubloading] = useState(false);
  useEffect(()=>{
      GetShipmentList();
      GetCustomer();
  },[invoiceno]);
  const GetShipmentList=()=>{
      setSubloading(true);
      axios({
          method: 'post',
          url: Global.baseUrl+'FetchShipmentByinvoiceno',
          headers:headers1,
          data: {
            invoice_no:invoiceno,
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
              var data=response.data.value;
              setList(data);
          }
          else
          {
              setList();
          }
          setSubloading(false);
          setLoading(false);
        })
        .catch(function (error) {
          if(error.response.status==401){
              toast.error(error.response.statusText);
              logout();
              navigate('/');
              window.location.reload();
          }
          setSubloading(false);
          setLoading(false);
          // console.log(error);
        });
  }
  const GetCustomer=()=>{
    setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'FetchCustomerByinvoiceno',
        headers:headers1,
        data: {
          invoice_no:invoiceno,
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            var data=response.data.value;
            setShipmentdata(data);
        }
        else
        {
            setShipmentdata();
        }
        setSubloading(false);
        setLoading(false);
      })
      .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        setLoading(false);
        // console.log(error);
      });
}
const handlePrint = () => {
    window.print();
};
  return(
      <>
      {/* <UserLeftPanel/> */}
      {/* <main className='home-section'> */}
          {/* <Header/> */}
          <ToastContainer/>
      {Loading==true?<MainLoader/>:
          <>
          <div className='col-12 p-3'>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                  {/* <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                      <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                          <li className="breadcrumb-item">
                              <Link to={`/Admin-Dash`}>
                                  <i className="bx bx-home text-primary-400 fs-6"></i>
                              </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">Customers</li>
                      </ol>
                  </nav>
                  <div className="d-flex justify-content-between">
                      <div className="">
                          <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                              <i className="bx bx-arrow-back "></i>
                          </button>
                      </div>
                      <div className="ps-2 ">
                          <h2 className="h4">Invoice</h2>
                      </div>
                  </div> */}
                  <button className='btn btn-primary' onClick={handlePrint}>Print Page</button>
              </div>
          </div>
          <div className="card mb-1">
                <div className="card-body">
                    <div className="border border-dark border-3 p-3 mb-3 text-center">
                        <h3>INVOICE No. {invoiceno}</h3>
                        <h3>GST No. {shipmentdata.gst_num}</h3>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="pb-4">
                                <h6>Invoice To</h6>
                                <h6 className="smallinvoice">{shipmentdata.name}</h6>
                                <h6 className="smallinvoice">{shipmentdata.address}</h6>
                                <h6 className="smallinvoice">{shipmentdata.city}</h6>
                                <h6 className="smallinvoice">{shipmentdata.country}</h6>
                                <h6 className="smallinvoice">{shipmentdata.userphone}</h6>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="pb-4">
                                <h6>Company Info</h6>
                                <h6 className="smallinvoice">{shipmentdata.company_name}</h6>
                                <h6 className="smallinvoice">{shipmentdata.company_address}</h6>
                                <h6 className="smallinvoice">{shipmentdata.phone}</h6>
                                {/* <h6 className="smallinvoice">{shipmentdata.invoice_month_year}</h6> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="card mb-1">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                        <table className="table table-hover">
                            <thead>
                            
                                <tr>
                                    <th className="border-gray-200">HSNID #</th>
                                    <th className="border-gray-200">Services</th>
                                    <th className="border-gray-200">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>HSNID</td>
                                        <td>Shipping Service</td>
                                        <td>{shipmentdata.service_charge} {shipmentdata.default_currency}</td>
                                    </tr>            
                            </tbody>
                        </table>
                        <div className="col-12 text-end p-5">
                            <div className="pb-4">
                                <h3 className=""><strong> SUBTOTAL : </strong>{shipmentdata.service_charge} {shipmentdata.default_currency}</h3>
                                <h3 className=""><strong>GST ({shipmentdata.default_service_tax}%) : </strong>{shipmentdata.service_tax} {shipmentdata.default_currency}</h3>
                                <h3 className=""><strong>Total : </strong>{shipmentdata.total_amt} {shipmentdata.default_currency} {shipmentdata.default_currency}</h3>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          <section className="data_table">
              <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                  
                  <table className="table table-hover">
                      <thead>
                      
                          <tr>
                              <th className="border-gray-200">AWB #</th>
                              <th className="border-gray-200">Entery Date</th>
                              <th className="border-gray-200">Delivery Date</th>
                              <th className="border-gray-200">Weight</th>
                              <th className="border-gray-200">Destination</th>
                              <th className="border-gray-200">COD Value</th>
                              <th className="border-gray-200">Shipment Type</th>
                              <th className="border-gray-200">Shipment Charge</th>
                              <th className="border-gray-200">GST ({shipmentdata.default_service_tax} %)</th>
                              <th className="border-gray-200">Total</th>
                          </tr>
                      </thead>
                      {Subloading==true && <ListLoader/>}
                      {list!=undefined && Subloading==false ?
                      <tbody>
                          {list.map((ship,index)=>{
                              return(
                              <tr key={index}>
                                  <td>{ship.slip_no}</td>
                                  <td>{ship.entrydate}</td>
                                  <td>{ship.delivery_date}</td>
                                  <td>{ship.volumetric_weight}</td>
                                  <td>{ship.receivercountry}</td>
                                  <td>{ship.total_cod_amt} {shipmentdata.default_currency}</td>
                                  <td>{ship.booking_mode}</td>
                                  <td>{ship.service_charge} {shipmentdata.default_currency}</td>
                                  <td>{ship.service_tax} {shipmentdata.default_currency}</td>
                                  <td>{ship.total_amt} {shipmentdata.default_currency}</td>
                              </tr>
                          )})}                 
                      </tbody>
                      :
                      <tbody><tr><td colSpan={8}>Record Not Found</td></tr></tbody>
                      }
                  </table>
                  
                  
              </div>
          </section>
          </div>
          </>
      }
          {/* <Footer/> */}
      {/* </main> */}
      </>

  );
}
export default Invoicedetail;