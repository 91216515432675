import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams,Link } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import AuthUser from "../../AuthUser";
import {CopyToClipboard} from 'react-copy-to-clipboard';


function CustomerDetail(){
    let navigate = useNavigate();
    const {UniqueId} = useParams();
    const{headers1,headers2,logout,getDefaultCurrency,getDays}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Details, setDetails] = useState('');
    const [ShipmentDetails, setShipmentDetails] = useState([]);
    const [bookedShipment, setbookedShipment] = useState('');
    const [intransitShipment, setintransitShipment] = useState('');
    const [deliveredShipment, setdeliveredShipment] = useState('');
    const [TotalCr, setTotalCr] = useState('');
    const [TotalDr, setTotalDr] = useState('');
    const [WalletHistory, setWalletHistory] = useState([]);
    const [CreditHistory, setCreditHistory] = useState([]);
    const [Invoice, setInvoice] = useState([]);
    const [TotalPaid, setTotalPaid] = useState('');
    const [TotalDue, setTotalDue] = useState('');
    const [last_paid, setlast_paid] = useState('');
    const [alltransaction, setalltransaction] = useState([]);
    const [UnpaidInvoice, setUnpaidInvoice] = useState([]);
    const [LastPaidAmt, setLastPaidAmt] = useState('0.00');
    const [Method, setMethod] = useState('Cash');
    const [RefrenceNo, setRefrenceNo] = useState('');
    const [Remark, setRemark] = useState('');
    const [RechargeWallet, setRechargeWallet] = useState('0.00');
    const [RechargeCredit, setRechargeCredit] = useState('0.00');
    useEffect(()=>{
        getCutomerDetail();
        getCutomerInvoice();
        getCutomerUnpaidInvoice();
        getCutomerInvoiceDetail();
        getCutomerShipmentDetail();
        getCutomerWalletDetail();
        getCutomerCreditDetail();
    },[]);
    const getCutomerDetail=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerDetail',
            headers:headers1,
            data: {
                UniqueId:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDetails(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getCutomerShipmentDetail=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerShipmentDetail',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
                days:getDays()
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setShipmentDetails(data.shipments);
                setbookedShipment(data.booked);
                setdeliveredShipment(data.delivered);
                setintransitShipment(data.intransit);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const getCutomerWalletDetail=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerWalletDetail',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setTotalCr(data.totalcr);
                setTotalDr(data.totaldr);
                setWalletHistory(data.history);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const getCutomerInvoice=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerInvoice',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
                type:'All'
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setInvoice(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const getCutomerUnpaidInvoice=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerInvoice',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
                type:'UN PAID'
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setUnpaidInvoice(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const getCutomerInvoiceDetail=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerInvoiceDetail',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setTotalPaid(data.paidamt);
                setTotalDue(data.dueamt);
                setlast_paid(data.last_paid);
                setalltransaction(data.alltransaction);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const RecievedPayment=(value)=>{
        if(value>=Math.floor(parseFloat(TotalDue))){
            toast.warning(`Value must be Less than or equal to ${TotalDue}`);
            value=TotalDue;
        }
        setLastPaidAmt(value);
        let extra=value;
        UnpaidInvoice.map((item,index) => {
            if(extra>=item.due_amout){
                UnpaidInvoice[index]['amt']=item.due_amout;
            }
				
			if((extra-item.due_amout)>=0)
				extra=extra-item.due_amout;
			else
			{	
                UnpaidInvoice[index]['amt']=extra;
				extra=0;
			}
         })
    }
    const HandleSubmit=()=>{
        if(Math.floor(parseFloat(LastPaidAmt))>0){
            axios({
                method: 'post',
                url: Global.baseUrl+'updateCutomerInvoice',
                headers:headers1,
                data: {
                    UniqueId:UniqueId,
                    Invoice:UnpaidInvoice,
                    LastPaidAmt:LastPaidAmt,
                    Method:Method,
                    RefrenceNo:RefrenceNo,
                    Remark:Remark
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    getCutomerInvoice();
                    getCutomerUnpaidInvoice();
                    getCutomerInvoiceDetail(); 
                    setLastPaidAmt('0.00');
                    setMethod('Cash');
                    setRefrenceNo('');
                    setRemark('');
                    toast.success(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
                // setLoading(false);
              });
        }else{
            toast.error('Please Enter Recieved Amount');
        }
    }
    const HandleWalletRecharge=()=>{
            axios({
                method: 'post',
                url: Global.baseUrl+'updateCutomerWallet',
                headers:headers1,
                data: {
                    UniqueId:UniqueId,
                    Recharge:RechargeWallet,
                    // Abailable:Details.available_wallet,
                    // Wallet:Details.wallet
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    getCutomerWalletDetail();
                    getCutomerDetail();
                    setRechargeWallet('0.00');
                    toast.success(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
                // setLoading(false);
              });
    }
    const getCutomerCreditDetail=()=>{
        setLoading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'getCutomerCreditDetail',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCreditHistory(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            setLoading(false);
          });
    }
    const HandleCreditRecharge=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'updateCutomerCredit',
            headers:headers1,
            data: {
                UniqueId:UniqueId,
                Recharge:RechargeCredit,
                Abailable:Details.available_credit,
                Limit:Details.credit_limit
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                getCutomerDetail();
                getCutomerCreditDetail();
                setRechargeCredit('0.00');
                toast.success(response.data.message);
            }else{
                toast.warning(response.data.message);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
            // setLoading(false);
          });
    }
    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>
            :
            <section className="my-3">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="card border-0 cust_profile_head">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="">
                                    <button className="btn btn_nav btn-sm" type="button" onClick={()=>navigate(-1)}>
                                    <i className="bx bx-arrow-back "></i>
                                    </button>
                                </div>
                                <div className="ps-2 ">
                                    {Details.prev_id!="" &&
                                        <a href={`/CustomerDetail/${Details.prev_id}`} className="btn btn_nav btn-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Previous Customer">
                                            <i className="bx bx-chevron-up "></i>
                                        </a>
                                    }
                                    {Details.next_id!="" &&
                                        <a href={`/CustomerDetail/${Details.next_id}`} className="btn btn_nav btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Next Customer">
                                            <i className="bx bx-chevron-down "></i>
                                        </a>
                                    }
                                </div>
                            </div>
                            <figure><img src="assets/img/profile.png" className="img-fluid"/></figure>
                            <h4>{Details.name} {Details.status=='Y' &&<a href="" className="text-primary" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Active Customer"><i className="bx bx-check-double"></i></a>}</h4>
                            <h6>{Details.company}</h6>
                            <span className="badge bg-warning text-dark "><small>Joined Since: {Details.entrydate}</small></span>
                            
                            <hr/>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-2">
                                    <div className="icon_pr">
                                    <a href={`callto:${Details.phone}`} className="text-primary" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={`Call:${Details.phone}`}><i className="bx bx-mobile"></i></a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="icon_pr">
                                    <a href={`mailto:${Details.email}`} className="text-primary" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title={`Mail:${Details.email}`}><i className="bx bx-envelope"></i></a>
                                    </div>
                                </div>
                            </div>

                            <section className="customer_pro_bx_l">
                                <h5>Customer Details</h5>
                                <div className="row">
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-phone"></i> Phone Number</small>
                                        <p><strong>{Details.phone}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-envelope"></i> Email</small>
                                        <p><strong>{Details.email}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-map"></i> Location</small>
                                        <p><strong>{Details.city}, {Details.country} - {Details.pincode}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-building"></i> Company Name</small>
                                        <p><strong>{Details.company}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-file"></i> Invoice Cycle</small>
                                        <p><strong>{Details.cust_invoice}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <small className="text-muted"><i className="bx bx-buildings"></i> Operational Hub</small>
                                        {Details.hub_id!='0'? <p><strong>{Details.hub_name} ,{Details.hub_city} - {Details.hub_country}</strong></p>:<p><strong>-</strong></p> }
                                    </div>
                                </div>
                            </section>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 customer_box_ei">
                        <div className="customer_top_boxx mb-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="card">
                                    <div className="card-body">
                                    <h2><i className="bx bx-shield"></i> Credit Limit</h2>
                                    <h3><small>{Details.gDefaultCurrency} </small>{Details.credit_limit}</h3>
                                    <h6><small>Used Credit:</small> <small>{Details.gDefaultCurrency} </small>{parseFloat(Details.credit_limit-Details.available_credit).toFixed(2)}</h6>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2><i className="bx bx-wallet"></i> Wallet Balance</h2>
                                            <h3><small>{Details.gDefaultCurrency} </small>{parseFloat(TotalCr-TotalDr).toFixed(2)}</h3>
                                            <h6><small>Used Balance:</small> <small>{Details.gDefaultCurrency} </small>{parseFloat(TotalCr-TotalDr).toFixed(2)}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 text-end">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-gray-700">Actions</button>
                                    <button type="button" className="btn btn-gray-700 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span className="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to={`/CustomerEdit/${UniqueId}`}>Edit Customer</Link></li>
                                        <li><Link className="dropdown-item" to={`/CustomerChangePassword/${UniqueId}`}>Change Password</Link></li>
                                        <li><Link className="dropdown-item" to={`/CustomerShipments/All/${UniqueId}`}>Shipment Booking History</Link></li>
                                        <li><Link className="dropdown-item" to={`/CustomerServices/All/${UniqueId}`}>Custom Master Pricing</Link></li>
                                        <li><Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#ManageCredit">Manage Credit Limit</Link></li>
                                        <li><Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#WalletRecharge">Add money to Wallet</Link></li>
                                        <li><Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#Receive_payments">Enter Receive Payment</Link></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#menu_shipments_history">Shipments</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#menu_transactions">Transactions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#menu_invoice">Invoices</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#menu_wallet_his">Wallet History</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#menu_credit_hisot">Credit Limit History</a>
                            </li>
                        </ul>

                        <div className="tab-content customer_boxs">
                            <div className="tab-pane container fade " id="menu_shipments_history">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-0 shadow  bg-light">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                                            <i className="bx bx-package"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h2 className="h6 text-gray-400 mb-0"> Booked</h2>
                                                            <h3 className="fw-extrabold mb-0">{bookedShipment}</h3>
                                                        </div>
                                                        <small className="text-gray-500">
                                                            Last {getDays()} days
                                                        </small> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-0 shadow bg-light">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                                            <i className="bx bx-run"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h2 className="h6 text-gray-400 mb-0"> In Transit</h2>
                                                            <h3 className="fw-extrabold mb-0">{intransitShipment}</h3>
                                                        </div>
                                                        <small className="text-gray-500">
                                                            Last {getDays()} days
                                                        </small> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-0 shadow bg-light">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                                            <i className="bx bx-check-double"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h2 className="h6 text-gray-400 mb-0"> Delivered</h2>
                                                            <h3 className="fw-extrabold mb-0">{deliveredShipment}</h3>
                                                        </div>
                                                        <small className="text-gray-500">
                                                            Last {getDays()} days
                                                        </small> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="data_table">
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>					
                                                    <th className="border-gray-200">AWB#</th>
                                                    <th className="border-gray-200">Origin</th>
                                                    <th className="border-gray-200">Destination</th>
                                                    <th className="border-gray-200">Status</th>
                                                </tr>
                                            </thead>
                                            {ShipmentDetails.length > 0 ?
                                            <tbody>
                                                {ShipmentDetails.map((ship,index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <div>
                                                                    <Link to={`/ShipmentDetails/${ship.slip_no}`} className="fw-bold">{ship.slip_no}</Link>
                                                                    <a href="" data-bs-toggle="tooltip" aria-label="Copy" data-bs-original-title="Copy"><i className="bx bx-copy"></i></a>
                                                                </div>
                                                                <small>{ship.entrydate}</small>
                                                            </td>
                                                            <td><span className="fw-normal text-capitalize">{ship.sendercity}, {ship.sendercountry}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{ship.receivercity}, {ship.receivercountry}</span></td>
                                                            <td><span dangerouslySetInnerHTML={{__html:ship.shipmentstatus}}></span></td>
                                                        </tr>  
                                                    );
                                                })}
                                            </tbody>
                                                :
                                                <tbody>
                                                    <tr><td rowSpan={4}>Record Not Found</td></tr>
                                                </tbody>
                                                }
                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane container fade" id="menu_transactions">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h6 className="small text-gray-500 mb-0"> Current Outstanding Balace</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{TotalDue}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                        <h6 className="small text-gray-500 mb-0"> Total Paid Amount</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{TotalPaid}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h6 className="small text-gray-500 mb-0"> Last Paid Amount</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{last_paid}</h3>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <section className="data_table">
                                    <div className="row mt-4 justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <h6>Customer Transaction History</h6>
                                        </div>
                                        <div className="col-12 col-md-4 text-end">
                                            <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Receive_payments"><i className="bx bx-bulb"></i>Enter Receive Payments</a>
                                        </div>
                                    </div>
                                    
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>					
                                                    <th className="border-gray-200">#</th>
                                                    <th className="border-gray-200">Entry Date</th>
                                                    <th className="border-gray-200">Paid Amount</th>
                                                    <th className="border-gray-200">Invoice Number</th>
                                                    <th className="border-gray-200">Payment Mode</th>
                                                </tr>
                                            </thead>
                                            {alltransaction.length>0 ?
                                            <tbody>
                                                {alltransaction.map((item,index)=>{
                                                    return(
                                                        <tr>
                                                            <td>
                                                                {index+1}
                                                            </td>
                                                            <td><span className="fw-normal text-capitalize">{item.date}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{Details.gDefaultCurrency}{item.amount}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{item.invoice}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{item.method}</span></td>
                                                        </tr>                   
                                                    );
                                                })}
                                            </tbody>
                                            :
                                                <tbody>
                                                    <tr><td rowSpan={4}>Record Not Found</td></tr>
                                                </tbody>
                                            }
                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane container active " id="menu_invoice">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>					
                                                <th className="border-gray-200">#</th>
                                                <th className="border-gray-200">Invoice Number</th>
                                                <th className="border-gray-200">Month-Year</th>
                                                <th className="border-gray-200">Cycle</th>
                                                <th className="border-gray-200">Total Amount ({Details.gDefaultCurrency})</th>
                                                <th className="border-gray-200">Paid Amount ({Details.gDefaultCurrency})</th>
                                                <th className="border-gray-200">Due Amount ({Details.gDefaultCurrency})</th>
                                                <th className="border-gray-200">Invoice Status</th>
                                            </tr>
                                        </thead>
                                        {Invoice.length>0?
                                        <tbody>
                                            {Invoice.map((data,index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>
                                                                <div>
                                                                    <Link to={`/Invoicedetail/${data.invoice_id}`} className="fw-bold">{data.invoice_id}</Link>
                                                                    <CopyToClipboard text={data.invoice_id} onCopy={()=>{toast.success(data.invoice_id+' Copied To Clipboard')}}><i className="bx bx-copy"></i></CopyToClipboard>
                                                                </div>
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{data.invoice_month_year}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{data.invoice_type}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{data.total_payment}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{data.paid_amount}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{data.due_amout}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{data.invoice_status}</span></td>
                                                    </tr>                       
                                                );
                                            })}
                                        </tbody>
                                        :
                                        <tbody><tr><td colSpan={8}>Record Not Found</td></tr></tbody>
                                        }
                                    </table>
                                    
                                </div>
                            </div>
                            <div className="tab-pane container fade" id="menu_wallet_his">
                            {/* <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h6 className="small text-gray-500 mb-0"> Total Credit</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{parseFloat(TotalCr).toFixed(2)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                        <h6 className="small text-gray-500 mb-0"> Total Debit</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{parseFloat(TotalDr).toFixed(2)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/> */}
                                <section className="data_table">
                                    <div className="row mt-4 justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <h6>Customer Wallet History</h6>
                                        </div>
                                        <div className="col-12 col-md-4 text-end">
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#WalletRecharge"><i className="bx bx-bulb"></i>Recharge Wallet</button>
                                        </div>
                                    </div>
                                    
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>					
                                                    <th className="border-gray-200">#</th>
                                                    <th className="border-gray-200">Entry Date</th>
                                                    <th className="border-gray-200">Transaction Id</th>
                                                    <th className="border-gray-200">Amount</th>
                                                    <th className="border-gray-200">Payment Mode</th>
                                                    <th className="border-gray-200">Payment Detail</th>
                                                </tr>
                                            </thead>
                                            {WalletHistory.length > 0 ?
                                            <tbody>
                                                {WalletHistory.map((history,index)=>{
                                                    return(
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.date}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.transaction_id}</span></td>
                                                        <td>
                                                            {history.Dr>0?
                                                            <span className="fw-normal text-capitalize text-danger">-{Details.gDefaultCurrency}{history.Dr}</span>
                                                            :
                                                            <span className="fw-normal text-capitalize text-success">+{Details.gDefaultCurrency}{history.Cr}</span>
                                                            }
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.mode}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.detail_type}</span></td>
                                                    </tr> 
                                                    );
                                                })}                  
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr><td rowSpan={4}>Record Not Found</td></tr>
                                            </tbody>
                                            }
                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane container fade" id="menu_credit_hisot">
                            <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                            <h6 className="small text-gray-500 mb-0"> Total Limit</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{Details.credit_limit}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card border-1 shadow ">
                                            <div className="card-body">
                                                <div className="row d-block d-xl-flex align-items-center">
                                                    <div className="col-12 px-xl-0">
                                                        <div className="d-none d-sm-block">
                                                        <h6 className="small text-gray-500 mb-0"> Used Limit</h6>
                                                            <h3 className="fw-extrabold mb-0"><small>{Details.gDefaultCurrency}</small>{parseFloat(Details.credit_limit-Details.available_credit).toFixed(2)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <section className="data_table">
                                    <div className="row mt-4 justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <h6>Customer Credit History</h6>
                                        </div>
                                        <div className="col-12 col-md-4 text-end">
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ManageCredit"><i className="bx bx-bulb"></i>Manage Credit</button>
                                        </div>
                                    </div>
                                    
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                        <thead>
                                                <tr>					
                                                    <th className="border-gray-200">#</th>
                                                    <th className="border-gray-200">Entry Date</th>
                                                    <th className="border-gray-200">Transaction Id</th>
                                                    <th className="border-gray-200">Shipment ID</th>
                                                    <th className="border-gray-200">Amount</th>
                                                    <th className="border-gray-200">Payment Detail</th>
                                                </tr>
                                            </thead>
                                            {CreditHistory.length > 0 ?
                                            <tbody>
                                                {CreditHistory.map((history,index)=>{
                                                    return(
                                                    <tr key={index}>
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.date}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.pay_id}</span></td>
                                                        <td><span className="fw-normal text-capitalize">{history.shipment_id}</span></td>
                                                        <td>
                                                            {history.Dr>0?
                                                            <span className="fw-normal text-capitalize text-danger">-{Details.gDefaultCurrency}{history.Dr}</span>
                                                            :
                                                            <span className="fw-normal text-capitalize text-success">+{Details.gDefaultCurrency}{history.Cr}</span>
                                                            }
                                                        </td>
                                                        <td><span className="fw-normal text-capitalize">{history.detail}</span></td>
                                                    </tr> 
                                                    );
                                                })}                  
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr><td rowSpan={4}>Record Not Found</td></tr>
                                            </tbody>
                                            }
                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Footer/>
            <div class="modal fade" id="Receive_payments">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Receive Payments</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                            <div class="alert alert-info">
                            <small>Enter Payments allows you to record received payments and update invoices efficiently for customer.</small>
                            </div>
                        

                        <div class="row mt-3">
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="number" class="form-label">Enter Received Amount:</label>
                                        <input type="number" required onChange={(e)=>RecievedPayment(e.target.value)} value={LastPaidAmt} min="1" class="form-control" placeholder="Enter amount"/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="email" class="form-label">Payment Received Method:</label>
                                        <select class="form-select" onChange={(e)=>setMethod(e.target.value)}>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="Cheque Deposit">Cheque Deposit</option>
                                        <option value="Online Payment Gateawy">Online Payment Gateawy</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="email" class="form-label">Reference no#:</label>
                                        <input type="text" class="form-control" value={RefrenceNo} onChange={(e)=>setRefrenceNo(e.target.value)} placeholder="Enter Reference number"/>
                                    </div>
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Remarks/NOTE:</label>
                                        <input type="text" class="form-control" value={Remark} onChange={(e)=>setRemark(e.target.value)} placeholder="Enter Remarks/NOTE"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="card">
                                <div class="card-body">
                                        <label for="" class="form-label">Total Outstanding Amount:</label>
                                    <h3 class="bold">{Details.gDefaultCurrency}{TotalDue}</h3>
                                    <hr/>
                                    <label for="" class="form-label">Customer:</label>
                                    <h6>{Details.company}</h6>
                                    <small>Account no: {Details.uniqueid}</small>
                                </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <section class="pt-4">
                            <h6>Outstanding Invoices:</h6>
                            <table class="table table-striped table-hover">
                                <thead class="table-dark">
                                <tr>
                                    <th>#</th>
                                    <th>DESCRIPTION</th>
                                    <th>INVOICE DATE</th>
                                    <th>ORIGINAL AMOUNT</th>
                                    <th>OPEN BALANCE</th>
                                    <th>PAYMENT</th>
                                </tr>
                                </thead>
                                {UnpaidInvoice.length>0?
                                <tbody>
                                    {UnpaidInvoice.map((Inv,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>Invoice #{Inv.invoice_id}</td>
                                                <td>{Inv.entry_date}</td>
                                                <td>{Inv.total_payment}</td>
                                                <td>{Inv.due_amout}</td>
                                                <td><input type="number" value={Inv.amt} readOnly class="form-control" id="text" placeholder="Enter amount"/></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                :
                                <tbody><tr><td colSpan={6}>Record Not Found</td></tr></tbody>
                                }
                            </table>
                        </section>

                        

                    </div>

                    <div class="modal-footer">
                        <button type="button" onClick={()=>HandleSubmit()} class="btn btn-primary">Receive Payments</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>

                    </div>
                </div>
            </div>
            <div class="modal fade" id="WalletRecharge">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Recharge Wallet</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                            <div class="alert alert-info">
                            <small>Enter Payments allows you to record received payments and update wallet efficiently for customer.</small>
                            </div>
                        

                        <div class="row mt-3">
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="number" class="form-label">Enter Amount:</label>
                                        <input type="number" required onChange={(e)=>setRechargeWallet(e.target.value)} value={RechargeWallet} min="1" class="form-control" placeholder="Enter amount"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="card">
                                <div class="card-body">
                                        <label for="" class="form-label">Current Wallet Balance:</label>
                                    <h3 class="bold">{Details.gDefaultCurrency}{parseFloat(TotalCr-TotalDr).toFixed(2)}</h3>
                                    <hr/>
                                    <label for="" class="form-label">Customer:</label>
                                    <h6>{Details.company}</h6>
                                    <small>Account no: {Details.uniqueid}</small>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" onClick={()=>HandleWalletRecharge()} class="btn btn-primary">Add Money To Wallet</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>

                    </div>
                </div>
            </div>
            <div class="modal fade" id="ManageCredit">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Manage Credit</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                            <div class="alert alert-info">
                            <small>Enter Payments allows you to record received payments and update Credit efficiently for customer.</small>
                            </div>
                        

                        <div class="row mt-3">
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="number" class="form-label">Enter Amount:</label>
                                        <input type="number" required onChange={(e)=>setRechargeCredit(e.target.value)} value={RechargeCredit} min="1" class="form-control" placeholder="Enter amount"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="card">
                                <div class="card-body">
                                    <label for="" class="form-label">Credit Limit:</label>
                                    <h3 class="bold">{Details.gDefaultCurrency}{Details.credit_limit}</h3>
                                    <label for="" class="form-label">Available Credit Limit:</label>
                                    <h3 class="bold">{Details.gDefaultCurrency}{Details.available_credit}</h3>
                                    <hr/>
                                    <label for="" class="form-label">Customer:</label>
                                    <h6>{Details.company}</h6>
                                    <small>Account no: {Details.uniqueid}</small>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" onClick={()=>HandleCreditRecharge()} class="btn btn-primary">Sattle Limit</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>

                    </div>
                </div>
            </div>
        </main>
        </>

    );
}
export default CustomerDetail;