import React, {useState, useEffect} from "react";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import ListLoader from "../Loaders/ListLoader";

function GeneralSetting(){
    let navigate = useNavigate();
    const[logo,setlogo]=useState('');
    const[old_logo,setold_logo]=useState('');
    const[printlogo,setprintlogo]=useState('');
    const[old_print_logo,setold_print_logo]=useState('');
    const[companyname,setcompanyname]=useState('');
    const[companyaddress,setcompanyaddress]=useState('');
    const[phone,setphone]=useState('');
    const[fax,setfax]=useState('');
    const[email,setemail]=useState('');
    const[gstno,setGstno]=useState('');
    const[lat,setlat]=useState('');
    const[long,setlong]=useState('');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        FetchCompanyData();
    },[]);
    function FetchCompanyData(){
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchCompanyData',
            headers:headers1,
            data: {}
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setlogo(data.logo);
                setold_logo(data.logo);
                setprintlogo(data.print_logo);
                setold_print_logo(data.print_logo);
                setcompanyname(data.company_name);
                setcompanyaddress(data.company_address);
                setphone(data.phone);
                setfax(data.fax);
                setemail(data.email);
                setGstno(data.gst_num);
                setlat(data.latitude);
                setlong(data.langitude);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    function handleSubmit(){
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'UpdateCompanyData',
            data: {
                'logo':logo,
                'old_logo':old_logo,
                'printlogo':printlogo,
                'old_printlogo':old_print_logo,
                'companyname':companyname,
                'companyaddress':companyaddress,
                'phone':phone,
                'fax':fax,
                'email':email,
                'gstno':gstno,
                'lat':lat,
                'long':long
            },
            headers:headers2
          })
          .then(function (response) {
            if(response.data.status == 200){
                toast.success(response.data.message);
            }else{
                toast.error(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    return(
        <div className="col-12 col-md">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="/Admin-Dash">
                                            <i className="bx bx-home text-primary-400 fs-6"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Settings</li>
                                    </ol>
                                </nav>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                        <i className="bx bx-arrow-back "></i>
                                        </button>
                                    </div>
                                    <div className="ps-2 ">
                                        <h2 className="h4">Settings</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                            </div>
                        </div>
                        <div className="card" style={{minHeight:'150px'}}>
                        {Loading==true ? <ListLoader/> :
                            <div className="card-body">
                            <h6>General Settings</h6>
                                <div className="row">
                                    <div className="col-8  mb-3">
                                        <div className="d-flex justify-content-between">
                                            <label for="" className="form-label">Logo</label>
                                            <small className="text-danger">Recomanded Logo Size: 250*60px</small>
                                        </div>
                                        <input type="file" onChange={(e)=>setlogo(e.target.files[0])} className="form-control" id="" placeholder="Please enter Logo details" />
                                    </div>
                                    <div className="col-2  mb-3">
                                    {logo!=undefined && logo!="" ? 
                                        <img src={`${Global.logoUrl}${logo}`} className="img-fluid" />
                                    :
                                        <img src="https://i.imgur.com/xnsOVMH.png" className="img-fluid" />
                                    }
                                    </div>
                                    <div className="col-8  mb-3">
                                        <div className="d-flex justify-content-between">
                                            <label for="" className="form-label">Print Logo</label>
                                            <small className="text-danger">Recomanded Logo Size: 250*60px</small>
                                        </div>
                                        <input type="file" onChange={(e)=>setprintlogo(e.target.files[0])} className="form-control" id="" placeholder="Please enter Print logo details" />
                                    </div>
                                    <div className="col-2  mb-3">
                                    {printlogo!=undefined && printlogo!="" ? 
                                        <img src={`${Global.logoUrl}${printlogo}`} className="img-fluid" />
                                    :
                                        <img src="https://i.imgur.com/xnsOVMH.png" className="img-fluid" />
                                    }
                                    </div>
                                    <div className="col-8  mb-3">
                                        <label for="" className="form-label">Company Name</label>
                                        <input type="text" onChange={(e)=>setcompanyname(e.target.value)} value={companyname} className="form-control" id="" placeholder="Please enter full Company name" />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Company address</label>
                                        <input type="text" onChange={(e)=>setcompanyaddress(e.target.value)} value={companyaddress} className="form-control" id="" placeholder="Please enter full Company address" />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Phone</label>
                                        <input type="text" onChange={(e)=>setphone(e.target.value)} value={phone} className="form-control" id="" placeholder="Please enter Phone number" />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Fax</label>
                                        <input type="text" onChange={(e)=>setfax(e.target.value)} value={fax} className="form-control" id="" placeholder="Please enter Fax number" />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">Email</label>
                                        <input type="email" onChange={(e)=>setemail(e.target.value)} value={email} className="form-control" id="" placeholder="Please enter Email address" />
                                    </div>
                                    <div className="col-8 mb-3">
                                        <label for="" className="form-label">GST No</label>
                                        <input type="email" onChange={(e)=>setGstno(e.target.value)} value={gstno} className="form-control" id="" placeholder="Please enter GST No." />
                                    </div>
                                    <div className="col-12 mb-3 mt-4">
                                        <strong>Google Map Location Coordinates</strong>
                                        <div className="row">
                                            <div className="col-6 mb-3">
                                                <label for="" className="form-label">Latitude</label>
                                                <input type="text" onChange={(e)=>setlat(e.target.value)} value={lat} className="form-control" id="" placeholder="Please enter Latitude Coordinates" />
                                            </div>
                                            <div className="col-6 mb-3">
                                                <label for="" className="form-label">Longitude</label>
                                                <input type="text" onChange={(e)=>setlong(e.target.value)} value={long} className="form-control" id="" placeholder="Please enter Longitude Coordinates" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Subloading==false?
                                <div className="d-grid col-md-2 mt-2">
                                    <button onClick={handleSubmit} className="btn btn-primary">Update Settings</button>
                                </div>
                                :
                                <div className="d-grid col-md-2 mt-2">
                                    <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                </div>
                                }
                            </div>
                        }
                        </div>

        </div>
    )
}
export default GeneralSetting;