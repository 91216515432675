import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function Weightdiscrepancylist(){
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [list, setList] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [SearchType, setSearchType] = useState('Any');
    const [SearchVal, setSearchVal] = useState('');
    useEffect(()=>{
        GetHubList();
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));
    const GetHubList=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Fetchadminweightdiscrepancylist',
            headers:headers1,
            data: {
                user_id:items.uniqueid,
                SearchType:SearchType,
                SearchVal:SearchVal
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>:
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="#">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Weight Discrepancy</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button">
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">All Weight Discrepancy</h2>
                        </div>
                    </div>
                </div>
            </div>
            
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="border-gray-200">Weight Applied Date</th>
                                <th class="border-gray-200">Order No.</th>
                                <th class="border-gray-200">AWB No.</th>
                                <th class="border-gray-200">Entered Weight</th>
                                <th class="border-gray-200">Weight Charges</th>
                                <th class="border-gray-200">Applied weight</th>
                                <th class="border-gray-200">Final Weight Charges</th>
                                <th class="border-gray-200">Difference</th>
                            </tr>
                        </thead>
                        {Subloading==true && <ListLoader/>}
                        {list!=undefined && list.length>0 && Subloading==false ?
                        <tbody>
                            {list.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td><span class="fw-normal text-capitalize">{item.entry_date}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.order_no}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.awb_no}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.entered_weight}KG {item.entered_height}x{item.entered_width}x{item.entered_length} CM</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.entred_weight_price}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.new_weight}KG {item.new_height}x{item.new_width}x{item.new_length} CM</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.new_weight_price}</span></td>
                                        <td><span class="fw-normal text-capitalize">{item.price_difference}</span></td>
                                    </tr>
                                );
                            })}                     
                        </tbody>
                        :
                        <tbody><tr><td colSpan={8} className="text-center text-danger"> Record Not Found </td></tr></tbody>
                        }
                    </table>
                    
                </div>
            </section>
            </>
            }
            <Footer/>
        </main>
        </>

    );
}
export default Weightdiscrepancylist;