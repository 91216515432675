import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function ZoneSetState(){
    const navigate = useNavigate();
    const [statelist, setStatelist] = useState([]);
    const [zonelist, setZonelist] = useState([]);
    const alphabets = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    const [searchalphabet, setSearchalphabet] = useState('A');
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);

    useEffect(()=>{
        Getstatelist('A');
        GetZonelist();
    },[]);

    const Getstatelist=(alpha)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetZoneStatelistbySearchValue',
            headers:headers1,
            data: {
                alphabet: alpha,
                // statevalue: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setStatelist(data);
            }
            else
            {
                setStatelist();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

    const GetZonelist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetZonelistbyzonetype',
            headers:headers1,
            data: {
                type:'Domestic'
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setZonelist(data);
            }
            else
            {
                setZonelist();
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const handlezoneset=(countryid,zonevalue)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'SetZoneDomesticstatewish',
            headers:headers1,
            data: {
                stateid: countryid,
                zonevalue: zonevalue
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success(response.data.message);
            }
            else
            {
                toast.warn(response.data.message);
            }
            setSubloading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    const handlealphabets=(e)=>{
        Getstatelist(e.target.innerText,'')
        setSearchalphabet(e.target.innerText);

    }

    const handleChange = (event) => {
        if(event.target.value!=""){
        const filtered = statelist.filter(item =>
            item.city.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setStatelist(filtered);
        }else{
            Getstatelist(searchalphabet);
        }
      };

    return(
        <>
        <MasterLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Zone Master</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Assign City to a Domestic Zone </h2>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row py-3">
                <div class="col col-md ">
                    <div class="input-group input-group-lg ">
                        <input type="text" class="form-control w-50" placeholder="Search City by name" onChange={(e)=>handleChange(e)}/>
                        <span class="input-group-text">
                            <i class="bx bx-search"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row py-3">
                <div class="col col-md">
                    <div class="input-group input-group-lg ">
                    {alphabets.map((alphabet, index) => (
                        <button key={index} type="button" class={searchalphabet == alphabet? "btn btn-outline-primary active" :"btn btn-outline-primary"} style={{"font-size": ".875rem",
                            "line-height": "1.5",
                            "border-radius": ".2rem","margin-right":"5px"}} onClick={handlealphabets}>{alphabet}</button>
                        ))}
                    </div>
                </div>
            </div>


            <div class="card" style={{minHeight:'100px'}}>
              <div class="card-body">
                <div class="row zone_selection_bx">
                {Subloading==true && <ListLoader/>}
                {statelist!=undefined && statelist.length>0 && Subloading==false ?
                    <>
                        {statelist.map((cl)=>{
                        return(
                            <div class="col-12 col-md-4">
                                <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <label for="" class="form-label">City</label>
                                            <h6 class="mt-2">{cl.state}</h6>
                                        </div>
                                        <div class="col-12 col-md">
                                            <label for="" class="form-label">Assign Zone</label>
                                            <select class="form-select" onChange={(e)=>handlezoneset(cl.id,e.target.value)}>
                                                <option>-- Select Zone --</option>
                                                {zonelist!= undefined &&
                                                    zonelist.map((zl)=>{
                                                        return(
                                                            <option value={zl.id} selected={zl.id == cl.zone_id? "selected":""}>{zl.name}</option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        );
                        })}
                    </>
                    :
                    ""
                    }
                </div>  
              </div>
            </div>
            </>}
            <Footer/>
        </main>
        </>

    );
}
export default ZoneSetState;