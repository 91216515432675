import React,{useState,useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useLocation,useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function Pincodelist()
{
    const navigate = useNavigate();
    const {id} = useParams();
    const location = useLocation();
    const [list, setList] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1); // Total number of pages
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [prevPageUrl, setPrevPageUrl] = useState(null);
    const pageRangeDisplayed = 2; 
    const [searchTerm, setSearchTerm] = useState('');
    const [servicelist, setServicelist] = useState([]);
    
    useEffect(()=>{
        const query = new URLSearchParams(location.search);
        const page = query.get('page') ? parseInt(query.get('page'), 10) : 1;
        const search = query.get('search') || '';
        setCurrentPage(page);
        setSearchTerm(search);
        GetPincodeList(page,search)
        GetServicelist();
    },[location.search]);

    const GetServicelist=()=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Servicelistforpincode',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setServicelist(data);
            }
            else
            {
                setServicelist();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }
    
    const GetPincodeList= async (page=1,val)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Pincodelist',
            headers:headers1,
            data: {
                pincode: val,
                serviceid:id,
                page:page
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
                setCurrentPage(response.data.value.current_page);
                setLastPage(response.data.value.last_page);
                setNextPageUrl(response.data.value.next_page_url); // URL for next page
                setPrevPageUrl(response.data.value.prev_page_url); // URL for previous page
            }
            else
            {
                setList();
                setCurrentPage();
                setLastPage(); 
                setNextPageUrl();
                setPrevPageUrl();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

const handledeletelocation=(id)=>{
    if (window.confirm('Do You  want to delete ?')) {
        setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'PincodeDelete',
        headers:headers1,
        data: {
            pincode: id
        }
    })
    .then(function (response) {
        if(response.data.status == 200)
        {
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
        GetPincodeList(location.search,'');
    })
    .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList(location.search,'');
    }
}
const handleservicestatus=(id,val)=>{
    if (window.confirm('Do you want to proceed?')) {
    setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'PickupStatusupdate',
        headers:headers1,
        data: {
            id:id,
            status: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            GetPincodeList(location.search,'');
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
      })   
      .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList(location.search,'');
    }
}

const handledeliverytatus=(id,val)=>{
    if (window.confirm('Do you want to proceed?')) {
    setSubloading(true);
    axios({
        method: 'post',
        url: Global.baseUrl+'DeliveredStatusupdate',
        headers:headers1,
        data: {
            id:id,
            status: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            GetPincodeList(location.search,'');
            toast.success(response.data.message);
        }
        else
        {
            toast.warn(response.data.message);
        }
        setSubloading(false);
      })   
      .catch(function (error) {
        if(error.response.status==401){
            toast.error(error.response.statusText);
            logout();
            navigate('/');
            window.location.reload();
        }
        setSubloading(false);
        // console.log(error);
      });
    }
    else
    {
        GetPincodeList(location.search,'');
    }
}

// Update the URL and fetch the data for the selected page
const handlePageChange = (pageNumber) => {
    const query = new URLSearchParams(location.search);
    query.set('page', pageNumber);
    navigate(`?${query.toString()}`);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const query = new URLSearchParams(location.search);
    query.set('search', searchTerm);
    query.set('page', 1); // Reset to page 1 on a new search
    navigate(`?${query.toString()}`);
  };
  // Handle Previous button click
  const handlePreviousPage = () => {
    if (prevPageUrl) {
      handlePageChange(currentPage - 1);
    }
  };

  // Handle Next button click
  const handleNextPage = () => {
    if (nextPageUrl) {
      handlePageChange(currentPage + 1);
    }
  };

  // Create pagination items for each page, showing a limited number of page numbers with ellipses
  const renderPageNumbers = () => {
    const pages = [];

    // Always show the first page
    if (currentPage > pageRangeDisplayed + 1) {
      pages.push(
        <li key={1} className="page-item">
          <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
        </li>
      );
      pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
    }

    // Show the range of pages around the current page
    for (let i = Math.max(1, currentPage - pageRangeDisplayed); i <= Math.min(lastPage, currentPage + pageRangeDisplayed); i++) {
      pages.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    // Always show the last page
    if (currentPage < lastPage - pageRangeDisplayed) {
      pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
      pages.push(
        <li key={lastPage} className="page-item">
          <button className="page-link" onClick={() => handlePageChange(lastPage)}>{lastPage}</button>
        </li>
      );
    }

    return pages;
  };

  const handleCountryChange = (event) => {
    const serviceid = event.target.value;
    window.location.href = '/Pincodelist/' + serviceid; // Redirects & reloads
};


    return (
        <>
            <MasterLeftPanel />
            <main className='home-section'>
                <Header />
                <ToastContainer />
                {Loading==true ? <MainLoader/> :
                <>
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Pincode</li>
                        </ol>
                    </nav>
                    <div class="d-flex">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">Pincode List</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="dropdown">
                        <button class="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <Link class="dropdown-item d-flex align-items-center" to={`/PincodeAdd`}>
                                <i class="bx bx-rocket text-primary-400 me-2 fs-5"></i>
                                Add New Pincode
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="custom_nav_data_table">
                       
                    </div>
                </div>
            
                <div class="col-12 col-md-6">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                        <div class="col col-md-6">
                            <select className="form-select" onChange={handleCountryChange}>
                                <option value="">-- Please Select --</option>
                                {servicelist.map((list) => {
                                    return (
                                        <option value={list.unique_id}>{list.services_name}, {list.product_type}, {list.booking_type}</option>
                                    );
                                })}
                            </select>
                        </div>
                            <div class="col col-md-6">
                                <form onSubmit={handleSearchSubmit}>
                                    <div class="input-group ">
                                        <input type="text" class="form-control w-50" placeholder="Search Pincode" onChange={handleSearchChange} />
                                        <button type="submit" class="input-group-text">
                                            <i class="bx bx-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>						
                                <th class="border-gray-200">#</th>
                                <th class="border-gray-200">City</th>
                                <th class="border-gray-200">Pincode</th>
                                <th class="border-gray-200">Pickup ON OFF</th>
                                <th class="border-gray-200">Delivery ON OFF</th>
                                <th class="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Subloading==true && <ListLoader/>}
                        {list != undefined && Subloading==false ?
                             <>
                             {list.map((pl,index)=>{
                                 return(
                                     <tr key={index}>
                                <td><span class="fw-normal text-capitalize">{index+1}</span></td>
                                <td><span class="fw-normal text-capitalize">{pl.city}</span></td>
                                <td><span class="fw-normal text-capitalize">{pl.pincode} </span></td>
                                <td>
                                    <div class="form-check form-switch  mb-2">
                                       <input class="form-check-input" type="checkbox" role="switch" checked={pl.pickup=='Y'? true : false} onChange={()=>{pl.pickup=='Y'? handleservicestatus(pl.id,'N'):handleservicestatus(pl.id,'Y')}} />
                                    </div>
                                </td>
                                <td>
                                    <div class="form-check form-switch  mb-2">
                                       <input class="form-check-input" type="checkbox" role="switch" checked={pl.delivery=='Y'? true : false} onChange={()=>{pl.delivery=='Y'? handledeliverytatus(pl.id,'N'):handledeliverytatus(pl.id,'Y')}} />
                                    </div>
                                </td>
                                <td>
                                    <Link to={`/pincodeEdit/${pl.pincode}`} class="btn btn-gray-100">Edit Pincode</Link>
                                    <button class="btn btn-gray-100" onClick={()=>handledeletelocation(pl.pincode)}>Delete Pincode</button>
                                </td>
                            </tr> 
                            );
                        })}   
                    </>
                        :
                        <tr>
                           <td colSpan="4" className="text-center text-danger">Record Not Found</td>
                        </tr>
                       }                  
                        </tbody>
                    </table>
                    
                </div>
            </section>
            <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                    {/* Previous Button */}
                    <li className={`page-item ${!prevPageUrl ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handlePreviousPage} disabled={!prevPageUrl}>
                        Previous
                        </button>
                    </li>

                    {/* Page Numbers */}
                    {renderPageNumbers()}

                    {/* Next Button */}
                    <li className={`page-item ${!nextPageUrl ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={handleNextPage} disabled={!nextPageUrl}>
                        Next
                        </button>
                    </li>
                    </ul>
                </nav>
                {/* <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div> */}
            </div>
            </>}
                <Footer />
            </main>
        </>
        );
}
export default Pincodelist;