import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import Global from "../../APIConfig";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";

function Weightdiscrepancy() {
    const navigate = useNavigate();
    const { headers1, headers2, logout } = AuthUser();
    const [Loading, setLoading] = useState(false);
    const [Subloading, setSubloading] = useState(true);
    const [file, setFile] = useState(null);
    useEffect(() => {
    }, []);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/assets/WeightDiscrepancy.csv`; // Path to your file
        link.download = 'WeightDiscrepancy.csv'; // Name of the file to be downloaded
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            
            // Show confirmation dialog before uploading
            if (window.confirm("Do you want to upload this file?")) {
                handleUpload(selectedFile);
            }
        }
      };

      const handleUpload = (selectedFile) => {
        if (!selectedFile) {
            toast.error('No file selected.');
            return;
        }
    
        setLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        axios.post(Global.baseUrl + 'uploadweightdiscrepancy', formData, {
            headers: headers2,
        })
        .then((response) => {
            toast.success('File uploaded successfully');
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            } else {
                toast.error('Failed to upload file. Please try again.');
            }
        })
        .finally(() => {
            setLoading(false);
        });
    };
    

    return (
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer />
            {Loading == true ? <MainLoader /> :
                <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="#">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipments</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <a className="btn btn_nav btn-sm" href="shipments.php">
                            <i className="bx bx-arrow-back "></i>
                            </a>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">Weight Discrepancy Import</h2>
                        </div>
                    </div>
                </div>
            
                <div className="btn-toolbar mb-2 mb-md-0">
                    
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md bulk_import fileinput">
                    <div className="card">
                    <div className="card-body">
                        <label className="custom-file-upload">
                                <input type="file" onChange={handleFileChange} accept=".csv,.xlsx,.xls" />
                            <i className="fa fa-cloud-upload"></i> Browse and Upload
                        </label>
                        <p>Please download and use sample template file Only csv file format is acceptable</p>
                    </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card border-0 shadow-sm p-3">
                        <div className="card-body">
                        <h3><i className="fa fa-lightbulb-o" aria-hidden="true"></i></h3>
                        <ul>
                            <li>Download the sample file and replace its data with your order data.</li>
                            <li>Make sure all mandatory fields are filled. Save the file and upload it back.</li>
                        </ul>

                        <div className="d-grid">
                            <a onClick={handleDownload} target="_blank" className="btn btn-primary btn-block">Download Sample File</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3">
                <div className="row">
                </div>
            </div>
            <div className="card">
            <div className="card-body">
                <h6>File Fields</h6>
                <table className="table table-striped table-bordered table-hover">
                    <tbody><tr>
                    <td title="weight of parcel">(1) AWBNO</td>
                    <td title="Type of Product Ex: Document, Parcel, pallate, Other">(2) New Height </td>
                    <td title="define mode either COD or Paid">(3) New Width</td>
                    <td title="define mode either COD or Paid">(4) New Length</td>
                    <td title="define mode either COD or Paid">(5) New Weight</td>
                    </tr>
                    <tr>
                    <td title="define mode either COD or Paid">(6) Service Charge</td>
                    <td title="define mode either COD or Paid">(7) Service Tax</td>
                    <td title="define mode either COD or Paid">(8) ESS Charges</td>
                    <td title="define mode either COD or Paid">(9) Elevated Risk Charges</td>
                    <td title="define mode either COD or Paid">(10) Resticted Destination Charges</td>
                    </tr>
                    <tr>
                    <td title="define mode either COD or Paid">(11) Remote Area Delivery Charges</td>
                    <td title="define mode either COD or Paid">(12) Fuel Surcharge</td>
                    <td title="define mode either COD or Paid">(13) Insurence Charge</td>
                    <td title="define mode either COD or Paid">(14) Cod Surcharge</td>
                    <td title="define mode either COD or Paid">(15) Total Amount</td>
                    </tr>
                    </tbody></table>
            </div>
            </div>
            </>
        }
            <Footer/>
        </main>
    </>
        );
}
export default Weightdiscrepancy;