import React,{useState,useEffect} from "react";
import MasterLeftPanel from "../include/MasterLeftPanel";
import Header from "../include/header";
import Footer from "../include/footer";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import {CopyToClipboard} from 'react-copy-to-clipboard';


function ServiceList()
{
    const navigate = useNavigate();
    const {type} = useParams();
    const [list, setList] = useState([]);
    const [showpage, setShowpage] = useState(type);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);
    const [SearchVal, setSearchVal] = useState('');
    
    useEffect(()=>{
         GetServicelist(type);
    },[]);
    
    const GetServicelist=(type)=>{
        setSubloading(true);
        setShowpage(type);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetServicelist',
            headers:headers1,
            data: {
                pagetype:type,
                searchvalue: SearchVal
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
            }
            else
            {
                setList();
            }
            setSubloading(false);
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            setLoading(false);
            // console.log(error);
          });
    }

    const handleservicestatus=(unique_id,val)=>{
        setSubloading(true);
        axios({
            method: 'post',
            url: Global.baseUrl+'Statusupdate',
            headers:headers1,
            data: {
                unique_id:unique_id,
                status: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                GetServicelist(showpage);
                toast.success(response.data.message);
            }
            else
            {
                toast.warn(response.data.message);
            }
            setSubloading(false);
          })   
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setSubloading(false);
            // console.log(error);
          });
    }

    const Servicedeleted=(id)=>{
        if (window.confirm('Do You  want to delete ?')) {
            setSubloading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'ServiceDelete',
                headers:headers1,
                data: {
                    unique_id:id
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                   toast.success(response.data.message);
                }
                else
                {
                    toast.warn(response.data.message);
                }
                setSubloading(false);
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setSubloading(false);
                // console.log(error);
              });
              GetServicelist(type);
          } else {
            GetServicelist(type);
          }
        
    }
    
    return (
        <>
        <MasterLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i class="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Price Master</li>
                        </ol>
                    </nav>
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <button class="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i class="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div class="ps-2 ">
                            <h2 class="h4">{showpage} Price Master</h2>
                        </div>
                    </div>
                </div>
                
                <div class="btn-toolbar mb-2 mb-md-0">
                    <div class="dropdown">
                        <button class="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div class="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <a class="dropdown-item d-flex align-items-center" href="/ServiceAdd">
                                <i class="bx bx-rocket text-primary-400 me-2 fs-5"></i>
                                Add New Service
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md">
                    <div class="custom_nav_data_table">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                              <button class={showpage =='All'?"nav-link active":"nav-link"} value="All" onClick={()=>GetServicelist('All')}>All Services</button>
                            </li>
                            <li class="nav-item">
                              <button class={showpage =='Domestic'?"nav-link active":"nav-link"} value="Domestic" onClick={()=>GetServicelist('Domestic')}>Domestic Services</button>
                            </li>
                            <li class="nav-item">
                              <button class={showpage =='International'?"nav-link active":"nav-link"} value="International" onClick={()=>GetServicelist('International')}>International Services</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="table-settings shipment_list" id="">
                        <div class="row align-items-start g-2" >
                            <div class="col col-md ">
                                <div class="input-group ">
                                    <input type="text" class="form-control w-50" placeholder="Search service By Name" onChange={(e)=>setSearchVal(e.target.value)} />
                                    <button class="input-group-text" onClick={()=>{GetServicelist(showpage)}}>
                                        <i class="bx bx-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="data_table">
                <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table class="table table-hover">
                        <thead>
                            <tr>						
                                <th class="border-gray-200">#</th>
                                <th class="border-gray-200">Service Unique Id</th>
                                <th class="border-gray-200">Service Name</th>
                                <th class="border-gray-200">Shipment Type</th>
                                <th class="border-gray-200">Mode</th>
                                <th class="border-gray-200">Weight Range</th>
                                <th class="border-gray-200">Status</th>
                                <th class="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Subloading==true && <ListLoader/>}
                        {list!=undefined && list.length>0 && Subloading==false ?
                             <>
                             {list.map((service,index)=>{
                                 return(
                                     <tr key={index}>
                                <td><span class="fw-normal text-capitalize">{index+1}</span></td>
                                <td><span class="fw-normal text-capitalize"><a href="">{service.unique_id}</a></span> <CopyToClipboard text={service.unique_id} onCopy={()=>{toast.success(service.unique_id+' Copied To Clipboard')}}><i className="bx bx-copy"></i></CopyToClipboard></td>
                                <td><span class="fw-normal text-capitalize"><a href="">{service.services_name}</a> </span></td>
                                <td><span class="fw-normal"><span class={service.product_type=='Parcel'? "badge bg-danger":"badge bg-warning"}>{service.product_type}</span></span></td>
                                <td><span class="fw-normal"><span class={service.booking_type=='Domestic' ? "badge bg-primary":"badge bg-dark"}>{service.booking_type}</span></span></td>
                                <td><span class="fw-normal text-capitalize">{service.startrange} ~ {service.endrange}KG</span></td>
                                <td>
                                    <div class="form-check form-switch  mb-2">
                                       <input class="form-check-input" type="checkbox" role="switch" checked={service.status=='Y'? true : false} onChange={()=>{service.status=='Y'? handleservicestatus(service.unique_id,'N'):handleservicestatus(service.unique_id,'Y')}} />
                                    </div>
                                </td>
                                <td>
                                    <Link to={`/ServicePricing/${service.unique_id}/${service.booking_type}`} class="btn btn-gray-100">Set/Update Pricing</Link>
                                    <Link to={`/ServiceOtherPricing/${service.unique_id}`} class="btn btn-gray-100">Set/Update OtherPricing</Link>
                                    <button type="button" class="btn btn-gray-100" onClick={()=>Servicedeleted(service.unique_id)}>Service Delete</button>
                                    <Link to={`/Pincodelist/${service.unique_id}`} class="btn btn-gray-100">Pinocde</Link>
                                </td>
                            </tr> 
                            );
                        })}   
                    </>
                        :
                        <tr>
                           <td colSpan="8" className="text-center text-danger">Record Not Found</td>
                        </tr>
                       }                    
                        </tbody>
                    </table>
                    
                </div>
            </section>

            <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-0">
                        <li class="page-item">
                            <a class="page-link" href="#">Previous</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">1</a>
                        </li>
                        <li class="page-item active">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">5</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div>
            </>
            }
            <Footer/>
        </main>
    </>
    );

}
export default ServiceList;